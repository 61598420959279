<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        {{
          inputs.language === 'en'
            ? 'Which of the following statements is correct regarding how to add the solvent at the beginning of the recrystallisation?'
            : 'Laquelle parmi les réponses suivantes est correcte concernant comment il faut ajouter le solvant au début de la recristallisation ?'
        }}
      </p>

      <v-radio-group v-model="inputs.howToAddSolvent" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab2Q5',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        howToAddSolvent: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'Add the cold solvent drop by drop until all the product dissolves, then heat the solution',
          value: 'coldDropByDrop',
        },
        {
          text: 'Add a large excess of the cold solvent until all the product dissolves, then heat the solution',
          value: 'excessCold',
        },
        {
          text: 'Add a small quantity of the cold solvent until some product dissolves, heat the solvent to boiling and then add small quantities of the solvent until the product just dissolves',
          value: 'smallCold',
        },
        {
          text: 'Heat the solvent and add it to the product until most of it dissolves',
          value: 'heatThenAdd',
        },
      ],
      optionsFr: [
        {
          text: 'Ajoutez le solvent froid goutte par goutte jusqu’à ce que tout le produit se dissout, ensuite chauffez la solution.',
          value: 'coldDropByDropFr',
        },
        {
          text: 'Ajoutez un excès du solvent froid pour dissoudre le produit complètement, ensuite chauffez la solution.',
          value: 'excessColdFr',
        },
        {
          text: 'Ajoutez une petite quantité du solvant froid jusqu’à ce qu’un peu du produit se dissout, chauffez le solvent au point d’ébullition, et ajoutez des petites quantités du solvant jusqu’à ce que le produit se dissout',
          value: 'smallCold',
        },
        {
          text: 'Ajoutez le solvant réchauffé au produit jusqu’à ce que le plupart se dissout',
          value: 'heatThenAddFr',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
